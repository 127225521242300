import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54c63cb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "player-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayerNotFound = _resolveComponent("PlayerNotFound")!
  const _component_WelcomeScreen = _resolveComponent("WelcomeScreen")!
  const _component_Quiz = _resolveComponent("Quiz")!
  const _component_AnswerInput = _resolveComponent("AnswerInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.userModeChangeError)
      ? (_openBlock(), _createBlock(_component_PlayerNotFound, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!_ctx.quizState)
            ? (_openBlock(), _createBlock(_component_WelcomeScreen, {
                key: 0,
                name: _ctx.player?.name
              }, null, 8, ["name"]))
            : ('guessing' in _ctx.quizState)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_Quiz, {
                    quiz: _ctx.quizState,
                    class: "quiz"
                  }, null, 8, ["quiz"]),
                  _createVNode(_component_AnswerInput, {
                    unit: _ctx.quizState.answer?.unit,
                    guessing: _ctx.quizState.guessing,
                    playerIsGuessing: _ctx.quizState.playerGuessing,
                    guess: _ctx.quizState.guess
                  }, null, 8, ["unit", "guessing", "playerIsGuessing", "guess"])
                ], 64))
              : _createCommentVNode("", true)
        ], 64))
  ]))
}
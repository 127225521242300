
import { defineComponent } from 'vue';
import Button from './ui/Button.vue';

export default defineComponent({
  name: 'PlayerNotFound',
  components: { Button },
  methods: {
    retry() {
      this.$router.go(0);
    },
    inspect() {
      this.$router.push('/');
    },
  },
});


import AnswerInput from '@/components/AnswerInput.vue';
import Quiz from '@/components/quiz/Quiz.vue';
import { useStore } from '@/store';
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import WelcomeScreen from '@/components/WelcomeScreen.vue';
import PlayerNotFound from '@/components/PlayerNotFound.vue';
import type { ClientPlayerQuizState } from '@shared/types';

export default defineComponent({
  name: 'Player',
  components: {
    Quiz,
    AnswerInput,
    WelcomeScreen,
    PlayerNotFound,
  },
  computed: {
    ...mapState(useStore, ['quizState', 'player', 'userModeChangeError']),
  },
  watch: {
    quizState(newQuiz: ClientPlayerQuizState, oldQuiz: ClientPlayerQuizState) {
      if (!newQuiz || !oldQuiz) return;

      if (
        (newQuiz.hint1 && !oldQuiz.hint1) ||
        (newQuiz.hint2 && !oldQuiz.hint2) ||
        (newQuiz.answer.value != undefined &&
          oldQuiz.answer.value == undefined) ||
        newQuiz.question !== oldQuiz.question ||
        newQuiz.guessing !== oldQuiz.guessing
      ) {
        navigator.vibrate(1001);
      }
    },
  },
});

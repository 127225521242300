
import { useStore } from '@/store';
import { mapActions } from 'pinia';
import { defineComponent } from 'vue';
import IconButton from './ui/IconButton.vue';

export default defineComponent({
  name: 'AnswerInout',
  components: { IconButton },
  props: {
    unit: String,
    guessing: Boolean,
    playerIsGuessing: Boolean,
    guess: Number,
  },
  data() {
    return {
      currentGuess: (this.guessing ? this.guess : '0000') as
        | number
        | string
        | undefined,
      guessVisible: false,
      guessFullyHidden: true,
      guessFullyHiddenTimeout: null as number | null,
    };
  },
  methods: {
    ...mapActions(useStore, ['playerGuessChanged', 'playerGuessing']),
    changeAnswerVisibility(visible: boolean) {
      if (this.guessing) return;

      this.guessVisible = visible;
      if (visible) {
        if (this.guessFullyHiddenTimeout) {
          clearTimeout(this.guessFullyHiddenTimeout);
        }
        this.guessFullyHidden = false;
        this.currentGuess = this.guess;
      } else {
        this.guessFullyHiddenTimeout = setTimeout(() => {
          this.guessFullyHidden = true;
          this.currentGuess = '0000';
        }, 120);
      }
    },
    sendAnswer() {
      if (!this.guessing) return;

      if (!this.playerIsGuessing && this.guess != undefined) {
        this.playerGuessing();
        return;
      }

      if (this.currentGuess === '' || this.currentGuess === undefined) {
        this.playerGuessChanged(null);
      }

      if (typeof this.currentGuess !== 'number') return;

      this.playerGuessChanged(this.currentGuess);
    },
    onGuessKeypress(event: KeyboardEvent) {
      if (
        event.key === 'Enter' ||
        (event.key.length === 1 && /[\d-]/.test(event.key))
      ) {
        return;
      }

      event.preventDefault();
      return false;
    },
    focusInput() {
      if (!this.playerIsGuessing || !this.guessing) return;

      (document.querySelector('#answer') as HTMLInputElement)?.focus();
    },
  },
  watch: {
    guess: {
      handler(newGuess: number | undefined, oldGuess: number | undefined) {
        if (oldGuess === newGuess) return;

        if (!this.guessing) {
          this.changeAnswerVisibility(this.guessVisible);
        } else {
          this.currentGuess = newGuess;
        }
      },
      immediate: true,
    },
    guessing: {
      handler(newGuessing: boolean) {
        if (!newGuessing) {
          this.changeAnswerVisibility(this.guessVisible);
        } else {
          this.currentGuess = this.guess;
        }
      },
      immediate: true,
    },
    playerIsGuessing(newPlayerGuessing: boolean) {
      if (!this.guessing || !newPlayerGuessing) return;

      setTimeout(() => {
        (document.querySelector('#answer') as HTMLInputElement)?.focus();
      });
    },
  },
});
